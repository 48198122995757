import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,{staticStyle:{"padding":"1%"},attrs:{"dark":"","color":"grey"}},[_c('h1',{staticStyle:{"margin-left":"3%"}},[_vm._v(" sk Bonobos ")])]),_c(VRow,_vm._l((_vm.members),function(item){return _c(VCol,{key:item},[_c(VCard,{staticClass:"mx-auto"},[_c(VList,{attrs:{"shaped":""}},[_c(VListItem,[_c(VListItemIcon,{attrs:{"color":"accent"}},[_c(VIcon,{domProps:{"textContent":_vm._s(item.icon)}})],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.text)}})],1)],1)],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }