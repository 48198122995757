import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"board"}},[_c('br'),(_vm.playerTurn)?_c(VCard,{attrs:{"color":"green"}},[_c(VCardTitle,{staticClass:"justify-center"},[_vm._v(" "+_vm._s(_vm.gameOver ? " P2 has Won! ":_vm.turnDescription.p1)+" ")])],1):_c(VCard,{attrs:{"color":"red"}},[_c(VCardTitle,{staticClass:"justify-center"},[_vm._v(" "+_vm._s(_vm.gameOver ? " P1 has Won! ":_vm.turnDescription.p2)+" ")])],1),_c('br'),_c('br'),_c('div',{staticClass:"game-board"},_vm._l((_vm.squares),function(square,index){return _c('div',{key:square,staticClass:"box",on:{"click":function($event){return _vm.boxClicked(index)}}},[(_vm.gameOver && square !== null && _vm.checkWinningCombination(index))?_c('div',[_c(VCard,{attrs:{"flat":"","color":"green"}},[_c(VCardTitle,[_c('h1',[_vm._v(_vm._s(square))])])],1)],1):_c('div',[_c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,[_c('h1',[_vm._v(_vm._s(square))])])],1)],1)])}),0),_c('br'),_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"large":"","block":"","color":"blue"},on:{"click":_vm.resetGame}},[_vm._v("Replay")])],1),_c(VCol,[_c(VBtn,{attrs:{"large":"","block":"","color":"orange"},on:{"click":_vm.resetScores}},[_vm._v("Reset Scores")])],1)],1),_c('div',{staticStyle:{"color":"white"}},[_c(VRow,[_c(VCol,[_c(VCardTitle,{staticClass:"justify-center"},[_vm._v(" Player 1's Score ")])],1),_c(VCol,[_c(VCardTitle,{staticClass:"justify-center"},[_vm._v(" Player 2's Score ")])],1)],1),_c(VRow,[_c(VCol,[_c(VCardTitle,{staticClass:"justify-center"},[_vm._v(" "+_vm._s(_vm.playerScores.p1Score)+" ")])],1),_c(VCol,[_c(VCardTitle,{staticClass:"justify-center"},[_vm._v(" "+_vm._s(_vm.playerScores.p2Score)+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }